<template>
  <div>
    <v-container>
      <p class="mb-n3">
        Which type of eye protection provides the best protection against a chemical splash?
      </p>

      <v-radio-group v-model="inputs.question1Value" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UCIrvineQuiz1S1Q10',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
      },
      options: [
        {
          text: 'Safety glasses with vented side shields',
          value: 'answer1',
        },
        {
          text: 'Safety glasses with non-vented side shields',
          value: 'answer2',
        },
        {
          text: 'Visorgogs',
          value: 'answer3',
        },
        {
          text: 'Impact safety goggles',
          value: 'answer4',
        },
        {
          text: 'Chemical splash safety goggles',
          value: 'answer5',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
